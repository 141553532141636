import React from 'react';
import SiteContentContext from './SiteContentContext';
import { SiteContentHtmlParser } from './SiteContentHtmlParser';
import { useQuery } from 'react-apollo';
import siteContentQuery from './siteContentQuery.gql';
import { getChannel } from '../../shop.config.custom';
import { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext/ChannelContext';

export const convertPropsToSiteContentObject = (item) => {
  const props = item.properties.reduce((merged, current) => {
    const propValue = current.value?.value
      ? current.value.value
      : current.value;
    merged[current.name] =
      current.type === 'html' ? (
        <SiteContentHtmlParser content={propValue} />
      ) : current.type === 'dropdown' && current.name.search('show') !== -1 ? (
        propValue === 'yes' ? (
          true
        ) : (
          false
        )
      ) : (
        propValue
      );
    return merged;
  }, {});
  return props;
};

const SiteContent = ({ children }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const channel = getChannel(selectedChannel.name);

  console.log(channel, ' - channel');

  const result = useQuery(siteContentQuery, {
    variables: { id: channel.siteContentId },
  });



  const startPage = result?.data?.startPage;
  const items = startPage?.data?.items;

  const siteContentObject =
    items && items.filter((item) => item.type === 'site-content')[0];

  const props =
    siteContentObject && convertPropsToSiteContentObject(siteContentObject);

  return (
    <SiteContentContext.Provider value={{ ...props }}>
      {children}
    </SiteContentContext.Provider>
  );
};

export default SiteContent;
